import { VuexModule, Module, Action } from 'vuex-module-decorators';
import AdminService from '@/services/AdminService';
import UserService from '@/services/UserService';
import UsersService from '@/services/UsersService';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';
@Module({ namespaced: true })
class InscriptionAssemblee extends VuexModule {
  @Action({ rawError: true })
  inscriptionAssemblee(data: InscriptionAssembleeDTO): Promise<string> {
    return UserService.inscriptionAssemblee(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  inscriptionAssembleeAdmin(data: InscriptionAssembleeDTO): Promise<string> {
    return UserService.inscriptionAssembleeAdmin(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getAllInscriptionsAssemblee(): Promise<InscriptionAssembleeDTO> {
    return AdminService.getAllInscriptionsAssemblee().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getAllInscriptions(): Promise<InscriptionAssembleeDTO> {
    return AdminService.getAllInscriptions().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getAllInscriptionsAssembleeByAdmin(): Promise<InscriptionAssembleeDTO> {
    return AdminService.getAllInscriptionsAssembleeByAdmin().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getInscriptionAdminById(id: string): Promise<InscriptionAssembleeDTO> {
    return AdminService.getInscriptionAdminById(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  createInscriptionAdmin(
    data: InscriptionAssembleeDTO
  ): Promise<InscriptionAssembleeDTO> {
    return AdminService.createInscriptionAdmin(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  updateInscriptionAdmin(
    data: InscriptionAssembleeDTO
  ): Promise<InscriptionAssembleeDTO> {
    return AdminService.updateInscriptionAdmin(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  deleteInscriptionAdmin(id: string): Promise<string> {
    return AdminService.deleteInscriptionAdmin(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  feedInscription(data): Promise<InscriptionAssembleeDTO> {
    return UsersService.feedInscription(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default InscriptionAssemblee;

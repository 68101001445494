import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-739ca8bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locale-changer" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CBreadcrumbItem = _resolveComponent("CBreadcrumbItem")!
  const _component_CBreadcrumb = _resolveComponent("CBreadcrumb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CBreadcrumb, {
      class: "breadcrumb",
      style: {"--cui-breadcrumb-divider":"'>'"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbItems, (breadcrumbItem) => {
          return (_openBlock(), _createBlock(_component_CBreadcrumbItem, {
            class: "bi-main",
            key: breadcrumbItem.path,
            href: breadcrumbItem.path
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(breadcrumbItem.meta.displayName)), 1)
            ]),
            _: 2
          }, 1032, ["href"]))
        }), 128)),
        (_ctx.route)
          ? (_openBlock(), _createBlock(_component_CBreadcrumbItem, {
              key: 0,
              class: "bi-item"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.route)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeLanguage && _ctx.changeLanguage(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: `locale-${i}`,
            value: locale
          }, _toDisplayString(locale), 9, _hoisted_2))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.$i18n.locale]
      ])
    ])
  ], 64))
}

import { defineComponent } from 'vue';
import Requestbox from '@/components/commandePermis/RequestBox.vue';
import InformationFrom from '@/components/commandePermis/InformationFrom.vue';
import NoCertificatForm from '@/components/commandePermis/NoCertificatForm.vue';
import TotalBox from '@/components/commandePermis/TotalBox.vue';
import Card from '@/components/Card.vue';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import * as Square from '@square/web-sdk';
import EventBus from '@/events/EventBus';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'ConfirmationCommandePermis',
  components: {
    Requestbox,
    Card,
    InformationFrom,
    TotalBox,
    NoCertificatForm,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        consignesNoCertificat:
          "The WWFN may verify the validity of the hunter's certificate number.",
        consignesInfosProfil:
          'If your information is not up to date, please update it now here.',
        confirmationCommande: 'Order confirmation',
        boitesDemandees: 'Licences available for purchase',
        methodePaiement: 'Method of payment',
        infosEnvoi: 'Shipping Information',
      },
      fr: {
        consignesNoCertificat:
          'LA PNWW pourra procéder à la vérification de la validité du No. de certificat du chasseur.',
        consignesInfosProfil:
          'Si vos informations ne sont pas à jour, veuillez les mettre à jour dès maintenant ici.',
        confirmationCommande: 'Confirmation de la commande',
        boitesDemandees: "Permis disponibles à l'achat",
        methodePaiement: 'Méthode de paiement',
        infosEnvoi: 'Informations',
      },
    },
  },
  data() {
    return {
      noCard: '',
      expirationDate: '',
      secureCode: '',
      commandInfo: {} as CommandePermisDTO,
      panier: [],
      errorCommand: false,
      message: '',
      totalPrice: 0,
      loading: false,
      cardInitialized: false,
      noCertificat: '',
    };
  },
  methods: {
    updateTotalPrice(selectedPermis) {
      this.totalPrice = 0;
      this.panier = selectedPermis;
      selectedPermis.forEach((perm) => {
        this.totalPrice = this.totalPrice + parseInt(perm.prix);
      });

      if (this.panier.length > 0 && !this.cardInitialized) {
        this.cardInitialized = true;
        this.initCard();
      }
    },
    goToRemerciment() {
      this.$router.push('/remercimentPermis');
    },
    getNoCertificatFormInterface(noCertificatFormInterface: undefined) {
      this.$options.noCertificatFormInterface = noCertificatFormInterface;
    },
    commander(cnon?: string) {
      this.commandInfoData(cnon);
      this.$loading.startLoading();
      this.$store
        .dispatch('Permis/commanderPermis', this.commandInfo)
        .then(() => {
          this.$loading.stopLoading();
          sessionStorage.panier = JSON.stringify(this.panier);
          this.goToRemerciment();
          this.loading = false;
        })
        .catch((message: string) => {
          this.errorCommand = true;
          this.message = 'erreurPaiement';
          this.$loading.stopLoading();
          this.loading = false;
        });
    },
    commandInfoData(cnon?: string) {
      const user = JSON.parse(sessionStorage.user);
      this.commandInfo.firstName = user.firstName;
      this.commandInfo.lastName = user.lastName;
      this.commandInfo.adress = user.address;
      this.commandInfo.city = user.city;
      this.commandInfo.postalCode = user.postalCode;
      this.commandInfo.telephone = user.telephone;
      this.commandInfo.email = user.email;
      this.commandInfo.province = user.province;
      this.commandInfo.memberId = user.memberId;
      this.commandInfo.total_price = this.totalPrice;
      this.commandInfo.commandes_permis_items = this.panier;
      this.commandInfo.transactionID = cnon;
      this.commandInfo.noCertificat =
        this.$options.noCertificatFormInterface.noCertificat;
    },
    async initCard() {
      const applicationId = process.env.VUE_APP_SQUARE_APPLICATION_ID;
      const locationId = process.env.VUE_APP_SQUARE_LOCATION_ID;
      const payments = await Square.payments(applicationId, locationId);
      const card = await payments!.card();
      await card.attach('#card-container');
      const button = this.$refs.cardButton as HTMLElement;
      button.addEventListener('click', async () => {
        this.loading = true;
        try {
          if (this.totalPrice > 0) {
            const result = await card.tokenize();
            this.commander(result.token!);
          } else {
            this.commander();
          }
        } catch (ex) {
          console.error(ex);
          this.loading = false;
        }
      });
    },
  },
  created() {
    const user = JSON.parse(sessionStorage.user);
    this.commandInfo.firstName = user.firstName;
  },
});


import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, numeric, minLength } from '@vuelidate/validators';
import EventBus from '../events/EventBus';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';
export default defineComponent({
  name: 'Login',
  setup() {
    const flow = ref(['year', 'month', 'calendar']);
    return {
      flow,
      v$: useVuelidate(),
    };
  },
  components: { Datepicker },
  i18n: {
    messages: {
      en: {
        bienvenue: 'Welcome to our portal',
        loginInfo: 'To log in, please complete the fields below :',
        nomFamille: 'Last name',
        dateNaissance: 'Birth date',
        numMembre: 'Member number',
        btnConnexion: 'Login',
      },
      fr: {
        bienvenue: 'Bienvenue sur notre portail',
        loginInfo:
          'Pour vous connecter, veuillez compléter les champs ci-dessous :',
        nomFamille: 'Nom de famille',
        dateNaissance: 'Date de naissance',
        numMembre: 'Numéro de membre',
        btnConnexion: 'Se connecter',
      },
    },
  },
  data() {
    return {
      lastName: '',
      birthDate: '',
      bandNumber: '',
      requiredMessage: 'Champ requis.',
      errorConnection: false,
      recaptcha: {} as ReCaptchaInstance,
    };
  },
  validations: {
    lastName: { required, minLength: minLength(3) },
    birthDate: { required },
    bandNumber: { required },
  },
  methods: {
    submitform() {
      this.$loading.startLoading();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$loading.stopLoading();
        return;
      }
      this.login();
    },
    formatDate(date: string) {
      let dateToFormat = new Date(date).toISOString().split('T')[0];
      return dateToFormat;
    },
    login() {
      if (this.lastName && this.birthDate && this.bandNumber) {
        let formattedBirthDate = this.formatDate(this.birthDate);
        this.recaptcha.execute('login').then((token) => {
          this.$store
            .dispatch('Auth/login', {
              lastName: this.lastName,
              birthDate: formattedBirthDate,
              bandNumber: this.bandNumber,
              recaptcha: token,
            })
            .then(() => {
              this.$loading.stopLoading();
              EventBus.emit('userStatusChange');
              this.$router.push({ name: 'Home' });
            })
            .catch(() => {
              this.$loading.stopLoading();
              this.errorConnection = true;
            });
        });
      }
    },
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    const userLogged = this.$store.getters['Auth/isLoggedIn'];
    if (userLogged) this.$router.push({ name: 'Home' });
  },
});

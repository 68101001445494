
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'InformationFrom',
  setup: () => ({ v$: useVuelidate() }),
  props: {
    distribution: { type: Number, required: true },
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        siteDistribution: 'Distribution site where you will pick up your order',
      },
      fr: {
        siteDistribution:
          'Site de distribution où vous irez chercher votre commande',
      },
    },
  },
  mounted() {
    this.emitInterface();
  },
  emits: ['update:distribution', 'interface'],
  data() {
    return {
      requiredMessage: 'Champ requis.',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      telephone: '',
      email: '',
      province: '',
      adresses: {} as Array<siteDistributionDTO>,
    };
  },
  validations: {
    distribution: { required },
  },
  methods: {
    uppercase(text: string) {
      return text.toUpperCase();
    },
    validate() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    emitInterface() {
      this.$emit('interface', {
        checkValide: () => this.validate(),
      });
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
  },
  created() {
    this.siteDistrib();
    const user = JSON.parse(sessionStorage.user);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.address = user.address;
    this.city = user.city;
    this.postalCode = user.postalCode;
    this.telephone = user.telephone;
    this.email = user.email;
    this.province = user.province;
  },
});

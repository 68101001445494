
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import EventBus from '../events/EventBus';
import commonMessages from '../locales/common.js';
export default defineComponent({
  name: 'LoginAdmin',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: '',
      password: '',
      requiredMessage: 'Champ requis.',
      errorConnection: false,
      recaptcha: {} as ReCaptchaInstance,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        bienvenue: 'Welcome to our portal',
        loginInfo:
          'To log in as an administrator, please complete the fields below:',
        password: 'Password',
        erreurs: {
          required: 'This field is required.',
          email: 'Invalid email format.',
          infosIncorrectes: 'The information entered is incorrect',
        },
      },
      fr: {
        bienvenue: 'Bienvenue sur notre portail',
        loginInfo:
          "Pour vous connecter en tant qu'administrateur, veuillez compléter les champs ci-dessous :",
        password: 'Mot de passe',
        erreurs: {
          required: 'Ce champ est requis.',
          email: 'Format du courriel invalide.',
          infosIncorrectes: 'Les informations entrées sont incorrectes.',
        },
      },
    },
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    submitform() {
      this.$loading.startLoading();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$loading.stopLoading();
        return;
      }
      this.login();
    },
    login() {
      if (this.email && this.password) {
        this.recaptcha.execute('login').then((token) => {
          this.$store
            .dispatch('Auth/loginAdmin', {
              email: this.email,
              password: this.password,
              recaptcha: token,
            })
            .then(() => {
              this.$loading.stopLoading();
              EventBus.emit('userStatusChange');
              this.$router.push({ name: 'Home' });
            })
            .catch(() => {
              this.errorConnection = true;
              this.$loading.stopLoading();
            });
        });
      }
    },
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    const userLogged = this.$store.getters['Auth/isLoggedIn'];
    if (userLogged) this.$router.push({ name: 'Home' });
  },
});


import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'InformationFrom',
  setup: () => ({ v$: useVuelidate() }),
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      requiredMessage: 'Champ requis.',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      telephone: '',
      email: '',
      province: '',
    };
  },
  methods: {
    uppercase(text: string) {
      return text.toUpperCase();
    },
  },
  created() {
    const user = JSON.parse(sessionStorage.user);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.address = user.address;
    this.city = user.city;
    this.postalCode = user.postalCode;
    this.telephone = user.telephone;
    this.email = user.email;
    this.province = user.province;
  },
});


import { defineComponent } from 'vue';
import provincesJson from '../data/provinces.json';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import ConfirmDialog from 'primevue/confirmdialog';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { ProduitDCDTO } from '@/types/store/DTO/ProduitDCDTO';

export default defineComponent({
  name: 'CreationModificationCoupon',
  components: {
    ConfirmDialog,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      provinces: provincesJson,
      adresses: {} as Array<siteDistributionDTO>,
      coupon: {} as CommandeDTO,
      creation: false,
      error: false,
      requiredMessage: 'champ requis',
      errorMessage: '',
      produitsDC: {} as Array<ProduitDCDTO>,
      cart: [] as Array<ProduitDCDTO>,
      selectedCoupon: [],
    };
  },
  validations: {
    coupon: {
      firstName: { required },
      lastName: { required },
      adress: { required },
      city: { required },
      postalCode: { required },
      telephone: { minLength: minLength(12) },
      email: { email },
      site_distribution_id: { required },
      province: { required },
      memberId: { required },
    },
  },
  created() {
    this.siteDistrib();
    this.produits();
    if (this.$route.params.id) {
      this.creation = false;
      this.getCommandInfo(this.$route.params.id as string);
    } else {
      this.creation = true;
    }
  },
  methods: {
    onBlur() {
      if (
        this.coupon.lastName != undefined &&
        this.coupon.lastName != '' &&
        this.coupon.memberId != '' &&
        this.coupon.memberId != undefined
      ) {
        this.$loading.startLoading();

        let data = {
          lastName: this.coupon.lastName,
          memberId: this.coupon.memberId,
        };

        this.$store.dispatch('Commande/feedCoupon', data).then((data) => {
          if (data != '') {
            let couponData = {
              adress: data.address,
              memberId: data.bandNumber,
              birthDate: data.birthDate,
              city: data.city,
              country: data.country,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              postalCode: data.postalCode,
              telephone: data.telephone,
              province: data.province,
              aggregateRootId: data.memberId,
            };
            this.coupon = couponData;
          } else {
            let couponData = {
              adress: null,
              memberId: this.coupon.memberId,
              birthDate: null,
              city: null,
              country: null,
              email: null,
              firstName: null,
              lastName: this.coupon.lastName,
              postalCode: null,
              telephone: null,
              province: null,
              aggregateRootId: null,
            };
            this.coupon = couponData;
          }
          this.$loading.stopLoading();
        });
      } else {
        let couponData = {
          adress: null,
          memberId: this.coupon.memberId,
          birthDate: null,
          city: null,
          country: null,
          email: null,
          firstName: null,
          lastName: this.coupon.lastName,
          postalCode: null,
          telephone: null,
          province: null,
          aggregateRootId: null,
        };
        this.coupon = couponData;
      }
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    produits() {
      this.$store
        .dispatch('ProduitDC/getAllProduitsDC')
        .then((data: Array<ProduitDCDTO>) => {
          this.produitsDC = data;
        });
    },
    updateSelectedProducts(produitDC) {
      // console.log(produitDC);
      let obj = this.cart.find((prod) => prod.id === produitDC.id);
      if (obj !== undefined) {
        let index = this.cart.indexOf(obj);
        this.cart.splice(index, 1);
      } else {
        this.cart.push(produitDC);
      }
      // console.log(this.cart);
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    getCommandInfo(id: string) {
      this.$store.dispatch('Commande/getCommandeById', id).then((data) => {
        if (data.ssamtaMemberId == null) {
          this.$router.push('/liste-coupons-admin');
        }
        this.coupon = data;
        this.coupon.produits.forEach((produit) => {
          this.updateSelectedProducts(produit);
          this.selectedCoupon.push(produit.id);
        });
      });
    },
    submitform() {
      if (this.creation) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
      }
      this.$confirm.require({
        message:
          "Voulez-vous renvoyer un nouveau coupon à l'utilisateur? (La modification sera sauvegardé en tout temps)",
        header: 'Renvoyer un coupon?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: () => {
          this.coupon.notify = true;
          this.saveCoupon();
        },
        reject: () => {
          this.coupon.notify = false;
          this.saveCoupon();
        },
      });
    },
    saveCoupon() {
      let post_data = {};
      let uri = 'modifyCommande';
      if (this.creation) {
        uri = 'createCommande';
        post_data = {
          memberId: this.coupon.aggregateRootId,
          site_distribution_id: this.coupon.site_distribution_id,
          products: this.cart,
          notify: this.coupon.notify,
        };
      } else {
        post_data = {
          id: this.coupon.id,
          memberId: this.coupon.ssamtaMemberId,
          site_distribution_id: this.coupon.site_distribution_id,
          products: this.cart,
          notify: this.coupon.notify,
        };
      }

      this.$store
        .dispatch('Commande/' + uri, post_data)
        .then(() => {
          this.$loading.stopLoading();
          if (this.$route.params.previousPage == 'RapportDc') {
            this.$router.push('/community-distribution-report');
          } else {
            this.$router.push('/liste-coupons-admin');
          }
        })
        .catch((error) => {
          this.errorMessage = error;
        });
    },
    deleteCoupon() {
      this.$store
        .dispatch('Commande/deleteCommande', this.$route.params.id as string)
        .then(() => {
          this.$loading.stopLoading();
          this.$router.push('/liste-coupons-admin');
        });
    },
  },
});

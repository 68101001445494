
import { defineComponent } from 'vue';
import { CSpinner } from '@coreui/vue';

export default defineComponent({
  name: 'Loading',
  components: {
    CSpinner,
  },
});

import { VuexModule, Module, Action } from 'vuex-module-decorators';
import UsersService from '@/services/UsersService';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
@Module({ namespaced: true })
class Users extends VuexModule {
  @Action({ rawError: true })
  getAllUsers(): Promise<UsersDTO> {
    return UsersService.getAllUsers().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getUserById(id: string): Promise<string> {
    return UsersService.getUserById(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  createUser(data: UsersDTO): Promise<UsersDTO> {
    return UsersService.createUser(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  modifyUser(data: UsersDTO): Promise<UsersDTO> {
    return UsersService.modifyUser(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  deleteUser(id: string): Promise<string> {
    return UsersService.deleteUser(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  adminMe(): Promise<string> {
    return UsersService.adminMe().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default Users;

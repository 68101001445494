
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import DynamicDialog from 'primevue/dynamicdialog';
import UpdateCommandeForm from '@/components/UpdateCommandeForm.vue';
//import { useDialog } from 'primevue/usedialog';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'RapportDc',
  components: { DataTable, Column, Card, Button, DynamicDialog },
  data() {
    return {
      rappots: [],
      copieRappots: [],
      ico: 'far',
      minDate: null,
      maxDate: null,
      isDisabled: true,
      search: '',
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getRapportsWithDates() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/commandesWithDate', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then((data: Array<CommandeDTO>) => {
            this.rappots = data;
            this.copieRappots = this.rappots;
          });
      }
    },
    checkDisabled() {
      if (this.minDate == null || this.maxDate == null) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    exportCSV() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/getCommandesCsvWithDates', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then();
      } else {
        this.$store.dispatch('Rapports/getCommandesCsv').then();
      }
    },
    allCommandes() {
      this.$store.dispatch('Rapports/getAllcommandes').then((data) => {
        this.rappots = data;
        this.copieRappots = this.rappots;
        console.log(data);
      });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      const hasReciepNumber = commande.commande.squarePaymentId
        ? this.getIfStringMatchSearch(commande.commande.squarePaymentId)
        : false;
      return (
        this.getIfStringMatchSearch(
          this.padLeadingZeros(commande.commande.id)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.lastName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.email) ||
        this.getIfStringMatchSearch(
          dateParserLong(commande.commande.created_at)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.memberId) ||
        this.getIfStringMatchSearch(commande.ssamtauser.adress) ||
        this.getIfStringMatchSearch(commande.ssamtauser.city) ||
        this.getIfStringMatchSearch(commande.ssamtauser.postalCode) ||
        hasReciepNumber ||
        this.getIfStringMatchSearch(commande.ssamtauser.telephone) ||
        this.getIfStringMatchSearch(commande.ssamtauser.mobile)
      );
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
    openDialog(data) {
      const dialog = this.$dialog;
      dialog.open(UpdateCommandeForm, {
        props: {
          header: 'Modifier la commande',
          style: {
            width: '50vw',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw',
          },
          modal: true,
        },
        data: {
          order_id: data.commande.id,
        },
        onClose: (event) => {
          this.allCommandes();
        },
      });
    },
  },
  created() {
    this.allCommandes();
  },
});


import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'TotalBox',
  data() {
    return {
      panier: {} as Panier,
      total: '0,00 $' as string | undefined,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  props: {
    totalPrice: Number,
  },
  methods: {
    updateInfos() {
      this.panier = JSON.parse(sessionStorage.panier);
      this.total = this.$props.totalPrice?.toFixed(2).toString() + ' $';
    },
  },
  mounted() {
    EventBus.on('panierChange', this.updateInfos);
  },
  created() {
    this.panier = JSON.parse(sessionStorage.panier);
    this.total = this.$props.totalPrice?.toFixed(2).toString() + ' $';
  },
});

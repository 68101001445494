import { VuexModule, Module, Action } from 'vuex-module-decorators';
import AdminService from '@/services/AdminService';
import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import UserService from '@/services/UserService';
import { AxiosResponse } from 'axios';
import { ProduitDCDTO } from '@/types/store/DTO/ProduitDCDTO';
@Module({ namespaced: true })
class ProduitDC extends VuexModule {
  @Action({ rawError: true })
  getAllProduitsDC(): Promise<ProduitDC> {
    return UserService.getAllProduitsDC().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default ProduitDC;

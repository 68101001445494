
import { defineComponent, PropType } from 'vue';
import MessageBoxType from '@/types/enums/MessageBoxType';

export default defineComponent({
  name: 'MessageBox',
  props: {
    type: { type: String as PropType<MessageBoxType>, required: true },
    HeaderMessage: { type: String, required: true },
    BodyMessage: { type: String, required: true },
  },
  data() {
    return {
      icon: '',
    };
  },
  created() {
    switch (this.type) {
      case 'info':
        this.icon = 'circle-info';
        break;
      case 'success':
        this.icon = 'circle-check';
        break;
      case 'warning':
        this.icon = 'circle-exclamation';
        break;
      case 'error':
        this.icon = 'circle-xmark';
        break;
    }
  },
});


import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import Tab from '@/components/rapports/RapportDc.vue';

export default defineComponent({
  name: 'UIKit',
  components: {
    MessageBox,
    Tab,
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
});

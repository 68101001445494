
import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
import { integer } from '@vuelidate/validators';
export default defineComponent({
  name: 'Requestbox',
  props: {
    totalPrice: Number,
  },
  data() {
    return {
      panier: {} as Panier,
      allPermis: new Array<PermisDTO>(),
      selectedPermis: new Array<PermisDTO>(),
      commandesPermis: new Array<CommandePermisDTO>(),
      availablePermis: new Array<PermisDTO>(),
      total: this.totalPrice,
      locale: this.$i18n.locale,
    };
  },
  methods: {
    getAllPermis() {
      this.$store
        .dispatch('Permis/getAllPermis')
        .then((data: Array<PermisDTO>) => {
          this.allPermis = data;
          this.getUserPermis();
        });
    },
    getUserPermis() {
      this.$store
        .dispatch('Permis/getUserPermis')
        .then((data: Array<CommandePermisDTO>) => {
          data.forEach((elem) => {
            this.commandesPermis = this.commandesPermis.concat(
              elem.commandes_permis_items
            );
          });
          this.filterAvailablePermis();
        });
    },
    filterAvailablePermis() {
      this.allPermis.forEach((perm) => {
        let value = this.commandesPermis.find((cp) => cp.id === perm.id);
        if (value === undefined) {
          this.availablePermis.push(perm);
        }
      });
    },
    updateSelectedPermis(permis) {
      let obj = this.selectedPermis.find((perm) => perm.id === permis.id);
      if (obj !== undefined) {
        let index = this.selectedPermis.indexOf(obj);
        this.selectedPermis.splice(index, 1);
      } else {
        this.selectedPermis.push(permis);
      }
      this.$emit('cart-change', this.selectedPermis);
    },
    recalculateTotal() {
      this.total = 0;
      this.selectedPermis.forEach((perm) => {
        this.total = this.total + parseInt(perm.prix);
      });
    },
  },
  created() {
    this.getAllPermis();
  },
  i18n: {
    sharedMessages: commonMessages,
  },
});


import { defineComponent } from 'vue';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'NoCertificatForm',
  i18n: {
    sharedMessages: commonMessages,
  },
  props: {
    noCertificat: String,
  },
  data() {
    return {
      noCertificatLocal: '',
    };
  },
  methods: {
    emitInterface() {
      this.$emit('interface', {
        noCertificat: this.noCertificatLocal,
      });
    },
  },
  mounted() {
    this.emitInterface();
  },

  emits: ['interface', 'noCertificatLocal'],
});


import { defineComponent } from 'vue';
import RapportCs from '@/components/rapports/RapportCs.vue';

export default defineComponent({
  name: 'RapportCommandesSupprimees',
  components: {
    RapportCs,
  },
});

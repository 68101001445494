
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import commonMessages from '../locales/common';
import axios from 'axios';
export default defineComponent({
  name: 'Elections',
  setup() {
    return { v$: useVuelidate() };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      fr: {
        candidat: 'Candidat',
        poser_question: 'Poser une question à un candidat',
        nom_complet: 'Votre nom complet',
        courriel: 'Votre courriel',
        question: 'Question (une seule question par formulaire)',
        bouton: {
          envoyer: 'Envoyer',
        },
        errors: {
          required: 'Ce champ est obligatoire',
          email: 'Veuillez entrer un courriel valide',
        },
      },
      en: {
        candidat: 'Candidate',
        poser_question: 'Ask a question to a candidate',
        nom_complet: 'Your full name',
        courriel: 'Your email',
        question: 'Question (one question per form)',
        bouton: {
          envoyer: 'Send',
        },
        errors: {
          required: 'This field is required',
          email: 'Please enter a valid email',
        },
      },
    },
  },
  data() {
    return {
      nom: '',
      candidat: '',
      courriel: '',
      message: '',
      candidats: [
        'Isabelle Baron',
        'Kevin Morais',
        'Martine Bruneau',
        'Nancy St-Gelais',
        'Nelson Lepage',
        'Pascale Dallaire',
        'Patrick Savoie',
        'Richard Bélanger',
        'Shirley Kennedy',
      ],
    };
  },
  methods: {
    submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$loading.startLoading();
        axios
          .post(process.env.VUE_APP_BACKEND_URL + 'contactElections', {
            nom: this.nom,
            candidat: this.candidat,
            courriel: this.courriel,
            message: this.message,
          })
          .then(() => {
            this.$loading.stopLoading();
            this.$toast.add({
              severity: 'success',
              summary: 'Succès',
              detail: 'Votre question a été envoyée avec succès',
              life: 3000,
            });
            this.nom = '';
            this.candidat = '';
            this.courriel = '';
            this.message = '';
            this.v$.$reset();
          })
          .catch(() => {
            this.$loading.stopLoading();
            this.$toast.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Une erreur est survenue, veuillez réessayer plus tard',
              life: 3000,
            });
          });
      }
    },
  },
  validations() {
    return {
      nom: { required },
      candidat: { required },
      courriel: { required, email },
      message: { required },
    };
  },
});

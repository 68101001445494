
import { defineComponent } from 'vue';
import RapportSd from '@/components/rapports/RapportSd.vue';

export default defineComponent({
  name: 'RapportsSites',
  components: {
    RapportSd,
  },
});

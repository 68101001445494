
import { defineComponent } from 'vue';
import RapportCp from '@/components/rapports/RapportCp.vue';

export default defineComponent({
  name: 'RapportCommandesPermisAdmin',
  components: {
    RapportCp,
  },
});


import { defineComponent } from 'vue';
import { CBreadcrumbItem } from '@coreui/vue';
import { RouteRecordRaw } from 'vue-router';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'Breadcrumb',
  components: {
    CBreadcrumbItem,
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      locales: ['fr', 'en'],
      route: '',
      breadcrumbItems: new Array<RouteRecordRaw>(),
    };
  },
  watch: {
    $route() {
      this.route = this.$route.meta.displayName;
      this.generateBreadcrum();
    },
  },
  methods: {
    changeLanguage() {
      localStorage.setItem('lang', this.$i18n.locale);
      window.location.reload();
    },
    generateBreadcrum() {
      this.breadcrumbItems = [];
      const routes = this.$router.options.routes;
      let parent = this.$route.meta.parent;
      while (parent != undefined) {
        const parentIndex = routes.findIndex(
          (breadcrumbItem: RouteRecordRaw) => breadcrumbItem.name == parent
        );
        if (parentIndex == -1) {
          parent = undefined;
        } else {
          parent = routes[parentIndex]?.meta?.parent;
          this.breadcrumbItems.push(routes[parentIndex]);
        }
      }
      this.breadcrumbItems.reverse();
    },
  },
  created() {
    this.generateBreadcrum();
  },
});

import { AdminInfos } from '@/types/store/auth/AdminInfos';
import { UserInfos } from '@/types/store/auth/UserInfos';
import axios from 'axios';
import moment from 'moment';

const API_URL = process.env.VUE_APP_BACKEND_URL;
class AuthService {
  login(data: UserInfos) {
    return axios.post(API_URL + 'login', data).then((response) => {
      if (response.data.userAuthToken) {
        const panier = { poisson: false, gibier: false, saumon: false };
        const age = this.getAge(data.birthDate);
        sessionStorage.setItem('panier', JSON.stringify(panier));
        sessionStorage.setItem('user', JSON.stringify(response.data));
        sessionStorage.setItem('age', JSON.stringify(age));
      }
      return response.data;
    });
  }
  loginAdmin(data: AdminInfos) {
    return axios
      .post(API_URL + 'administration/login', data)
      .then((response) => {
        if (response.data.access_token) {
          const panier = { poisson: false, gibier: false, saumon: false };
          sessionStorage.setItem('user', JSON.stringify(response.data));
          sessionStorage.setItem('panier', JSON.stringify(panier));
          // sessionStorage.setItem(
          //   'role',
          //   JSON.stringify(response.data.accountType)
          // );
        }
        return response.data;
      });
  }
  logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('panier');
    sessionStorage.removeItem('age');
  }
  getAge(dateNaiss: Date) {
    const dateNaissUser = moment(dateNaiss);
    const calculDate = new Date(2022, 9, 17, 0, 0, 0);
    const date = moment(calculDate);
    const years = date.diff(dateNaissUser, 'years');
    return years;
  }
}
export default new AuthService();

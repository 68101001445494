
import { defineComponent } from 'vue';
import { InscriptionAssembleeDTO } from '../types/store/DTO/InscriptionAssembleeDTO';
import Panel from 'primevue/panel';
import RadioButton from 'primevue/radiobutton';
export default defineComponent({
  name: 'CreationModificationInscription',
  data() {
    return {
      error: '',
      creation: false,
      inscription: {} as InscriptionAssembleeDTO,
    };
  },
  components: { Panel, RadioButton },
  created() {
    if (this.$route.params.id) {
      this.creation = false;
      this.getInscriptionInfo(this.$route.params.id as string);
    } else {
      this.creation = true;
    }
  },
  methods: {
    getMemberInfo() {
      if (
        this.inscription.nom != undefined &&
        this.inscription.nom != '' &&
        this.inscription.memberId != '' &&
        this.inscription.memberId != undefined
      ) {
        this.$loading.startLoading();

        let data = {
          lastName: this.inscription.nom,
          memberId: this.inscription.memberId,
        };

        this.$store
          .dispatch('InscriptionAssemblee/feedInscription', data)
          .then((data) => {
            if (data != '') {
              this.inscription.adresse = data.address;
              this.inscription.ville = data.city;
              this.inscription.codePostal = data.postalCode;
              this.inscription.telephone = data.telephone;
              this.inscription.email = data.email;
              this.inscription.age = data.age;
              this.inscription.prenom = data.firstName;
              this.inscription.memberId = data.bandNumber;
              this.inscription.nom = data.lastName;
              this.inscription.ssamtaMemberId = data.ssamtaMemberId;
            }
            this.$loading.stopLoading();
          })
          .catch((message: string) => {
            this.$loading.stopLoading();
          });
      }
    },
    getInscriptionInfo(id: string) {
      this.$store
        .dispatch('InscriptionAssemblee/getInscriptionAdminById', id)
        .then((data: InscriptionAssembleeDTO) => {
          this.inscription = data[0];
        });
    },
    deleteInscription() {
      if (confirm("Voulez-vous vraiment supprimer l'inscription?")) {
        this.$loading.startLoading();
        this.$store
          .dispatch(
            'InscriptionAssemblee/deleteInscriptionAdmin',
            this.$route.params.id as string
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
    saveInscription() {
      this.$loading.startLoading();
      if (this.creation) {
        this.$store
          .dispatch(
            'InscriptionAssemblee/createInscriptionAdmin',
            this.inscription
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      } else {
        this.$store
          .dispatch(
            'InscriptionAssemblee/updateInscriptionAdmin',
            this.inscription
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
  },
});


import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { dateParserLong } from '@/helpers/dateParserHelper';
import { UsersDTO } from '../types/store/DTO/UsersDTO';
import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'listeUtilisateurs',
  components: { DataTable, Column, Card },
  data() {
    return {
      search: '',
      userList: new Array<UsersDTO>(),
      userListCopie: new Array<UsersDTO>(),
      ico: 'far',
      selectedUser: {} as UsersDTO,
    };
  },
  methods: {
    getLastName(fullName: string) {
      var firstName = fullName.split(' ').slice(0, -1).join(' ');
      return firstName;
    },
    getFirstName(fullName: string) {
      var lastName = fullName.split(' ').slice(-1).join(' ');
      return lastName;
    },
    dateParserLong(date: string) {
      return dateParserLong(date);
    },
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getUsers() {
      this.$store
        .dispatch('Users/getAllUsers')
        .then((data: Array<UsersDTO>) => {
          this.userList = data;
          this.userListCopie = this.userList;
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfUserMatch(user: UsersDTO) {
      return (
        this.getIfStringMatchSearch(user.name) ||
        this.getIfStringMatchSearch(user.email) ||
        this.getIfStringMatchSearch(dateParserLong(user.created_at)) ||
        this.getIfStringMatchSearch(user.memberId) ||
        this.getIfStringMatchSearch(user.role.role)
      );
    },
    filter() {
      this.userList = this.userListCopie;
      const result = this.userList.filter(this.getIfUserMatch);
      this.userList = result;
    },
  },
  created() {
    this.getUsers();
  },
});

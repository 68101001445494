import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f261d08e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-boite" }
const _hoisted_2 = { class: "input-2" }
const _hoisted_3 = { class: "input-from mr" }
const _hoisted_4 = { class: "input-from" }
const _hoisted_5 = { class: "input-from mt" }
const _hoisted_6 = { class: "input-2 mt" }
const _hoisted_7 = { class: "input-from mr" }
const _hoisted_8 = { class: "input-from" }
const _hoisted_9 = { class: "input-from mt" }
const _hoisted_10 = { class: "input-2 mt" }
const _hoisted_11 = { class: "input-from mr" }
const _hoisted_12 = { class: "input-from" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('prenom')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Prénom",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.firstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('nom')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Nom",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.lastName]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('adresse')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Adresse",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address) = $event)),
        disabled: true
      }, null, 512), [
        [_vModelText, _ctx.address]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('ville')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Ville",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.city) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.city]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('province')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Ville",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.province) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.province]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('codePostal')), 1),
      _withDirectives(_createElementVNode("input", {
        class: "postalCode",
        type: "text",
        placeholder: "Code postal",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.postalCode) = $event)),
        disabled: true
      }, null, 512), [
        [_vModelText, _ctx.postalCode]
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('email')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "email",
          placeholder: "Courriel",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('telephone')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "tel",
          placeholder: "Téléphone",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.telephone) = $event)),
          disabled: true
        }, null, 512), [
          [_vModelText, _ctx.telephone]
        ])
      ])
    ])
  ]))
}
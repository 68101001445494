
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  i18n: {
    messages: {
      en: {
        besoinAide: 'Need help?',
        contact: 'Contact us :',
        administration: 'Administration',
        copyright: '© Wolastoqiyik 2022. Portal designed by ',
      },
      fr: {
        besoinAide: "Besoin d'aide?",
        contact: 'Contactez-nous :',
        administration: 'Administration',
        copyright: '© Wolastoqiyik 2022. Portail conçu par ',
      },
    },
  },
  data() {
    return {
      userLoggedIn: false,
    };
  },
  methods: {
    getUserConnexionStatus() {
      this.userLoggedIn = this.$store.getters['Auth/isLoggedIn'];
    },
  },
  created() {
    this.getUserConnexionStatus;
  },
});


import { defineComponent } from 'vue';
import provinces from '../values/provinces.json';
import states from '../values/states.json';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { UserUpdateDTO } from '@/types/store/DTO/UserUpdateDTO';
import commonMessages from '../locales/common';

export default defineComponent({
  name: 'DashBoard',
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      errorProvince: false,
      nom: this.getStoredInfo('lastName'),
      prenom: this.getStoredInfo('firstName'),
      adresse: this.getStoredInfo('address'),
      ville: this.getStoredInfo('city'),
      codePostal: this.getStoredInfo('postalCode'),
      email: this.getStoredInfo('email'),
      numeroTelephone: this.getStoredInfo('mobile'),
      province: this.getStoredInfo('province'),
      pays: this.getStoredInfo('country'),
      user: {} as UserUpdateDTO,
      form: {
        provinces: provinces,
        states: states,
        adresse: this.getStoredInfo('address'),
        ville: this.getStoredInfo('city'),
        code_postal: this.getStoredInfo('postalCode'),
        pays: this.getStoredInfo('country'),
        province: this.getStoredInfo('province'),
        etat: this.getStoredInfo('province'),
        email: this.getStoredInfo('email'),
        telephone: this.getStoredInfo('telephone'),
        mobile: this.getStoredInfo('mobile'),
      },
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        profileInfos: 'Profile information',
        listePays: {
          canada: 'Canada',
          etatsUnis: 'United States',
          france: 'France',
          paysBas: 'Netherlands',
          bolivie: 'Bolivia',
          nouvelleZelande: 'New Zealand',
          japon: 'Japan',
          autre: 'Other',
        },
        erreurs: {
          required: 'This field is required.',
          email: 'Please enter a valid email address',
          infosIncorrectes: 'The information entered is incorrect',
          phone: 'Invalid phone number',
        },
      },
      fr: {
        profileInfos: 'Informations du profil',
        listePays: {
          canada: 'Canada',
          etatsUnis: 'États-Unis',
          france: 'France',
          paysBas: 'Netherlands',
          bolivie: 'Bolivia',
          nouvelleZelande: 'New Zealand',
          japon: 'Japan',
          autre: 'Other',
        },
        erreurs: {
          required: 'Ce champ est requis.',
          email: 'Veuillez entrer une adresse courriel valide',
          infosIncorrectes: 'Les informations entrées sont incorrectes.',
          phone: 'Numéro de téléphone invalide',
        },
      },
    },
  },
  validations() {
    const phone = helpers.withMessage(
      this.$t('erreurs.phone'),
      helpers.regex(/^\(\d{3}\)\s\d{3}-\d{4}$/)
    );
    return {
      form: {
        adresse: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        ville: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        code_postal: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        pays: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        email: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
          email: helpers.withMessage(this.$t('erreurs.email'), email),
        },
        mobile: {
          phone: phone,
        },
        telephone: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
          phone: phone,
        },
      },
    };
  },
  methods: {
    CountryChange() {
      this.province = null;
    },
    submitForm() {
      this.v$.$validate();
      this.errorProvince = false;
      if (this.form.pays !== 'CA' && this.form.pays !== 'USA') {
        this.province = null;
        this.errorProvince = false;
      } else {
        if (this.province == null) {
          this.errorProvince = true;
        } else {
          this.errorProvince = false;
        }
      }
      if (!this.v$.$error && !this.errorProvince) {
        this.$loading.startLoading();
        this.updateUser();
      } else {
        console.log(this.v$.$errors);
        console.log(this.errorProvince);
      }
    },
    getStoredInfo(info: string) {
      const test = JSON.parse(sessionStorage.getItem('user') || '');
      for (const [key, value] of Object.entries(test)) {
        if (key === info) return value;
      }
    },
    updateUser() {
      this.user.address = this.form.adresse;
      this.user.aggregateRootId = JSON.parse(sessionStorage.user).memberId;
      this.user.authToken = JSON.parse(sessionStorage.user).userAuthToken;
      this.user.email = this.form.email;
      this.user.mobile = this.form.mobile == '' ? null : this.form.mobile;
      this.user.address = this.form.adresse;
      this.user.city = this.form.ville;
      this.user.postalCode = this.form.code_postal;
      this.user.province = this.province;
      this.user.country = this.form.pays;
      this.user.telephone = this.form.telephone;
      this.$store
        .dispatch('User/updateUser', this.user)
        .then(() => {
          this.$loading.stopLoading();
          this.updateStorage();
        })
        .catch(() => {
          this.$loading.stopLoading();
        });
    },
    updateStorage() {
      let user = JSON.parse(sessionStorage.user);
      user.address = this.user.address;
      user.city = this.user.city;
      user.postalCode = this.user.postalCode;
      user.email = this.user.email;
      user.mobile = this.user.mobile;
      user.telephone = this.user.telephone;
      user.country = this.user.country;
      user.province = this.user.province;
      sessionStorage.user = JSON.stringify(user);
      this.adresse = this.user.address;
      this.ville = this.user.city;
      this.codePostal = this.user.postalCode;
      this.email = this.user.email;
      this.numeroTelephone = this.user.mobile;
      this.pays = this.user.country;
      this.province = this.user.province;
    },
  },
});

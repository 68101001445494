import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c56653"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "titre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('confirmationInscription')), 1),
    _createVNode(_component_MessageBox, {
      HeaderMessage: _ctx.$t('inscriptionRecue.titre'),
      BodyMessage: _ctx.$t('inscriptionRecue.contenu'),
      type: _ctx.boxStyle.Success
    }, null, 8, ["HeaderMessage", "BodyMessage", "type"]),
    _createElementVNode("button", {
      class: "btn-demande",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'house'] }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('retourAccueil')), 1)
    ])
  ]))
}
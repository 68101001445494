
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
export default defineComponent({
  name: 'RapportEc',
  components: { DataTable, Column, Card },
  data() {
    return {
      adresses: [] as Array<siteDistributionDTO>,
      rappots: new Array<CommandeDTO>(),
      copieRappots: new Array<CommandeDTO>(),
      ico: 'far',
      site: -1,
      statut: -1,
      search: '',
      isAdmin: false,
      selectedOrder: null,
      sitesDistributionUser: [],
    };
  },
  methods: {
    exportCSV() {
      this.$store
        .dispatch('Rapports/getEtatCommandesCsv', {
          site: this.site,
          statut: this.statut,
        })
        .then();
    },
    filterDatatable() {
      this.rappots = this.copieRappots;
      console.log(this.site, this.statut);
      if (this.site === -1 && this.statut === -1) {
        return;
      }
      //console.log(this.site);
      const result = this.rappots.filter((rapport) => {
        console.log(rapport);
        if (this.isAdmin) {
          return (
            (rapport.site_distribution_id === this.site || this.site == -1) &&
            (rapport.pivot.distribuee == this.statut || this.statut == -1)
          );
        } else {
          return (
            (rapport.commande.site_distribution_id === this.site ||
              this.site == -1) &&
            (rapport.distribuee == this.statut || this.statut == -1)
          );
        }
      });
      this.rappots = result;
    },
    getBooleanDistribuee() {
      if (this.isAdmin) {
        return this.selectedOrder.pivot.distribuee;
      } else {
        return this.selectedOrder.distribuee;
      }
    },
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    getCommandes() {
      this.$store.dispatch('Users/adminMe').then((data) => {
        if (this.isAdmin) {
          this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
            data.forEach((commande) => {
              commande.produits.forEach((produit) => {
                if (produit.nom === 'Boîte de poisson') {
                  produit['type'] = 'poisson';
                } else if (produit.nom === 'Boîte de gibier') {
                  produit['type'] = 'gibier';
                } else if (produit.nom === 'Boîte de saumon') {
                  produit['type'] = 'saumon';
                }
                produit.ssamtaUser = commande.ssamtaUser;
                produit.site_distribution_id = commande.site_distribution_id;
                this.rappots.push(produit);
              });
            });
            this.copieRappots = this.rappots;
          });
        } else {
          this.sitesDistributionUser = data.distributions;
          this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
            data.forEach((data) => {
              this.rappots.push(data);
            });
            this.copieRappots = this.rappots;
          });
        }
      });
      this.siteDistrib();
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    distribuerCommande() {
      if (confirm('Confirmez la distribution')) {
        this.$loading.startLoading();
        // this.selectedOrder.nbBoitesDistribuees =
        //   this.selectedOrder.nbBoitesCommandees;
        this.$store
          .dispatch('Commande/reclamerCommande', this.selectedOrder)
          .then((data: { message: string }) => {
            this.$loading.stopLoading();
            // this.getCommandes();
            location.reload();
          })
          .catch((message: string) => {
            this.$loading.stopLoading();
          });
      }
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      return (
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(
          this.padLeadingZeros(commande.pivot.commande_id)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.lastName) ||
        this.getIfStringMatchSearch(
          commande.ssamtaUser.firstName + ' ' + commande.ssamtaUser.lastName
        )
      );
    },
  },
  created() {
    this.isAdmin = JSON.parse(sessionStorage.user).role == 'admin';
    this.getCommandes();
  },
  computed: {
    siteNom(): string {
      const siteDistribution = this.adresses.find(
        (adr) => adr.id === this.site
      );
      return siteDistribution !== undefined ? siteDistribution.ville : '';
    },
    getSiteDistributionByUser() {
      if (this.isAdmin) {
        return this.adresses;
      } else {
        return this.adresses.filter((site) => {
          // return this.sitesDistributionUser.includes(site);
          var result = false;
          this.sitesDistributionUser.forEach((element) => {
            if (element.id === site.id) {
              result = true;
            }
          });
          return result;
        });
      }
    },
  },
});

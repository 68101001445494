
import { defineComponent } from 'vue';
import RapportEc from '@/components/rapports/RapportEc.vue';

export default defineComponent({
  name: 'RapportEtatCommandes',
  components: {
    RapportEc,
  },
});

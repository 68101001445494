
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { defineComponent } from 'vue';
import commonMessages from '../locales/common';
import Dropdown from 'primevue/dropdown';
import Toast from 'primevue/toast';
export default defineComponent({
  name: 'Coupons',
  components: { Dropdown, Toast },
  data() {
    return {
      poissonId: '',
      gibierId: '',
      saumonId: '',
      produits: [],
      sitesDistribution: [],
      dateLimiteChangementSite: new Date('2024-11-08'),
      selectedSite: '',
      commande_id: null,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        consigneCoupons: 'Check the coupons to download',
        coupons: {
          saumon: 'Salmon box coupon',
          poisson: 'Fish box coupon',
          gibier: 'Game box coupon',
        },
        telecharger: 'Download',
        aucunCoupons: "You don't have coupons",
        siteDistribution: 'Distribution site',
        dateLimiteChangementSiteDepassee:
          'The deadline to change site is passed.',
        siteDistributionSauvegarde: 'Distribution site saved.',
      },
      fr: {
        consigneCoupons: 'Veuillez cocher les coupons à télécharger.',
        coupons: {
          saumon: 'Coupon de boîte de saumon',
          poisson: 'Coupon de boîte de poisson',
          gibier: 'Coupon de boîte de gibier',
        },
        telecharger: 'Télécharger',
        aucunCoupons: "Vous n'avez pas de coupons",
        siteDistribution: 'Site de distribution',
        dateLimiteChangementSiteDepassee:
          'La date limite pour changer de site est dépassée.',
        siteDistributionSauvegarde: 'Site de distribution sauvegardé.',
      },
    },
  },
  computed: {
    isBeforeDateLimite() {
      let date = new Date();
      return date < this.dateLimiteChangementSite;
    },
    getURLPoisson(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponPoisson/' + this.poissonId
      );
    },
    getURLViande(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponGibier/' + this.gibierId
      );
    },
    getURLSaumon(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponSaumon/' + this.saumonId
      );
    },
  },
  created() {
    this.$store.dispatch('Commande/getCoupons').then((data) => {
      this.produits = data.coupons;
      this.selectedSite = data.site;
      this.commande_id = data.commande_id;
    });

    this.$store
      .dispatch('SiteDistribution/getSiteDistribution')
      .then((data) => {
        console.log(data);
        data.forEach((site) => {
          this.sitesDistribution.push({
            label: site.ville.toUpperCase() + ' - ' + site.lieux,
            value: site.id,
          });
        });
      });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandDeer')
    //   .then((data: CommandeDTO) => {
    //     this.gibierId = data.uuid;
    //   });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandFish')
    //   .then((data: CommandeDTO) => {
    //     this.poissonId = data.uuid;
    //   });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandSaumon')
    //   .then((data: CommandeDTO) => {
    //     this.saumonId = data.uuid;
    //   });
  },
  methods: {
    getUrlCoupon(data) {
      return (
        process.env.VUE_APP_BACKEND_URL +
        'getCoupon/' +
        data.pivot.commande_id +
        '/' +
        data.pivot.uuid +
        '/' +
        data.type
      );
    },
    updateSiteDistribution() {
      if (!this.isBeforeDateLimite) {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('erreur'),
          detail: this.$t('dateLimiteChangementSiteDepassee'),
        });
        return;
      }

      if (this.selectedSite !== null) {
        this.$store
          .dispatch('Commande/updateSiteDistribution', {
            commande_id: this.commande_id,
            site_distribution_id: this.selectedSite,
            memberId: JSON.parse(sessionStorage.user).memberId,
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('succes'),
              detail: this.$t('siteDistributionSauvegarde'),
            });
          });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('erreur'),
          detail: this.$t('siteDistributionNonSelectionne'),
        });
      }
    },
  },
});


import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'RapportCs',
  components: { DataTable, Column, Card },
  data() {
    return {
      rappots: [],
      copieRappots: [],
      ico: 'far',
      minDate: null,
      maxDate: null,
      isDisabled: true,
      search: '',
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getRapportsWithDates() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/commandesSupprimeesWithDate', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then((data: Array<CommandeDTO>) => {
            this.rappots = data;
            this.copieRappots = this.rappots;
          });
      }
    },
    checkDisabled() {
      if (this.minDate == null || this.maxDate == null) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    exportCSV() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/getCommandesSupprimeesCsvWithDates', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then();
      } else {
        this.$store.dispatch('Rapports/getCommandesSupprimeesCsv').then();
      }
    },
    allCommandes() {
      this.$store.dispatch('Rapports/getAllDeletedCommandes').then((data) => {
        this.rappots = data;
        this.copieRappots = this.rappots;
        console.log(data);
      });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      const hasReciepNumber = commande.commande.squarePaymentId
        ? this.getIfStringMatchSearch(commande.commande.squarePaymentId)
        : false;
      return (
        this.getIfStringMatchSearch(
          this.padLeadingZeros(commande.commande.id)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.lastName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.email) ||
        this.getIfStringMatchSearch(
          dateParserLong(commande.commande.created_at)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.memberId) ||
        this.getIfStringMatchSearch(commande.ssamtauser.adress) ||
        this.getIfStringMatchSearch(commande.ssamtauser.city) ||
        this.getIfStringMatchSearch(commande.ssamtauser.postalCode) ||
        hasReciepNumber
      );
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
  },
  created() {
    this.allCommandes();
  },
});


import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'TotalBox',
  data() {
    return {
      panier: [],
      total: '0,00 $' as string | undefined,
      locale: this.$i18n.locale,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  props: {
    totalPrice: Number,
    cart: Array,
  },
  created() {
    this.panier = this.$props.cart;
    this.total = this.$props.totalPrice?.toFixed(2).toString() + ' $';
  },
});

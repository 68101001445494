
import { defineComponent } from 'vue';
import DashBoard from '@/components/DashBoard.vue';

export default defineComponent({
  name: 'Home',
  components: {
    DashBoard,
  },
});

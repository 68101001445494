
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'RemercimentPermis',
  components: {
    MessageBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        confirmationCommande: 'Order confirmation',
        commandeEnvoyee: {
          titre: 'Order sent successfully',
          contenu:
            'Thank you for your order. You will receive confirmation of your licence order by email.',
        },
      },
      fr: {
        confirmationCommande: 'Confirmation de la commande',
        commandeEnvoyee: {
          titre: 'Commande envoyée avec succès',
          contenu:
            'Merci pour votre commande. Vous recevrez une confirmation de votre commande de permis par courriel.',
        },
      },
    },
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  },
});

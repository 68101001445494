
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'Remerciment',
  components: {
    MessageBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        confirmationCommande: 'Order confirmation',
        commandeEnvoyee: {
          titre: 'Order sent successfully',
          contenu:
            'Thank you for your order. You will receive coupons by email. You will have to present them in electronic or printed version at the distribution site.',
        },
      },
      fr: {
        confirmationCommande: 'Confirmation de la commande',
        commandeEnvoyee: {
          titre: 'Commande envoyée avec succès',
          contenu:
            'Merci pour votre commande. Vous recevrez des coupons par courriel. Vous devrez les présenter en version électronique ou imprimée au site de distribution.',
        },
      },
    },
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  },
});


import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'Permis',
  components: {
    DataTable,
    Column,
    Card,
  },
  data() {
    return {
      permis: new Array<PermisDTO>(),
      commandesPermis: new Array<CommandePermisDTO>(),
      locale: this.$i18n.locale,
    };
  },
  methods: {
    getAllPermis() {
      this.$store
        .dispatch('Permis/getAllPermis')
        .then((data: Array<PermisDTO>) => {
          this.permis = data;
        });
    },
    getUserPermis() {
      this.$store
        .dispatch('Permis/getUserPermis')
        .then((data: Array<CommandePermisDTO>) => {
          data.forEach((elem) => {
            this.commandesPermis = this.commandesPermis.concat(
              elem.commandes_permis_items
            );
          });
        });
    },
  },
  created() {
    this.getAllPermis();
    this.getUserPermis();
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        permis: 'Licence',
        possede: 'Owned',
        date_expiration: 'Renewal date',
        acheter_permis: 'Buy a license',
      },
      fr: {
        permis: 'Permis',
        possede: 'Possédé',
        date_expiration: 'Date de renouvellement',
        acheter_permis: 'Acheter un permis',
      },
    },
  },
});


import { defineComponent } from 'vue';
import Breadcrumb from '@/components/header/Breadcrumb.vue';
import HeaderMenu from '@/components/header/HeaderMenu.vue';
export default defineComponent({
  name: 'Header',
  components: {
    Breadcrumb,
    HeaderMenu,
  },
});


import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'Requestbox',
  data() {
    return {
      panier: {} as Panier,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  methods: {
    goToDistributionComm() {
      this.$router.push('/community-distribution');
    },
    //1 poisson 2 gibier
    deleteBoite(boite: number) {
      switch (boite) {
        case 1:
          this.panier.poisson = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
        case 2:
          this.panier.gibier = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
        case 3:
          this.panier.saumon = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
      }
    },
    redirectEmpty() {
      if (!this.panier.gibier && !this.panier.poisson && !this.panier.saumon) {
        this.goToDistributionComm();
      }
    },
  },
  created() {
    if (sessionStorage.panier != undefined) {
      this.panier = JSON.parse(sessionStorage.panier);
    } else {
      this.goToDistributionComm();
    }
  },
});

<script setup>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

const dialogRef = inject('dialogRef');
const store = useStore();
const confirm = useConfirm();
const router = useRouter();
const $loading = inject('$loading');

const closeDialog = () => {
  dialogRef.value.close();
};

const coupon = ref({});
const produitsDC = ref([]);
const adresses = ref([]);
const cart = ref([]);
const selectedCoupon = ref([]);

getSiteDistrib();
getProduits();
getCommandInfo(dialogRef.value.data.order_id);

function getSiteDistrib() {
  store.dispatch('SiteDistribution/getSiteDistribution').then((data) => {
    adresses.value = data;
  });
}

function getProduits() {
  store.dispatch('ProduitDC/getAllProduitsDC').then((data) => {
    produitsDC.value = data;
  });
}

function updateSelectedProducts(produitDC) {
  // console.log(produitDC);
  let obj = cart.value.find((prod) => prod.id === produitDC.id);
  if (obj !== undefined) {
    let index = cart.value.indexOf(obj);
    cart.value.splice(index, 1);
  } else {
    cart.value.push(produitDC);
  }
}

function getCommandInfo(id) {
  store.dispatch('Commande/getCommandeById', id).then((data) => {
    if (data.ssamtaMemberId == null) {
      router.push('/liste-coupons-admin');
    }
    coupon.value = data;
    coupon.value.produits.forEach((produit) => {
      updateSelectedProducts(produit);
      selectedCoupon.value.push(produit.id);
    });
  });
}

function submitform() {
  debugger;
  confirm.require({
    message:
      "Voulez-vous renvoyer un nouveau coupon à l'utilisateur? (La modification sera sauvegardé en tout temps)",
    header: 'Renvoyer un coupon?',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Oui',
    rejectLabel: 'Non',
    accept: () => {
      coupon.value.notify = true;
      saveCoupon();
    },
    reject: () => {
      coupon.value.notify = false;
      saveCoupon();
    },
  });
}

function saveCoupon() {
  let post_data = {};
  let uri = 'modifyCommande';
  post_data = {
    id: coupon.value.id,
    memberId: coupon.value.ssamtaMemberId,
    site_distribution_id: coupon.value.site_distribution_id,
    products: cart.value,
    notify: coupon.value.notify,
  };

  //$loading.startLoading();

  store.dispatch('Commande/' + uri, post_data).then(() => {
    //$loading.stopLoading();
    closeDialog();
    //   if (this.$route.params.previousPage == 'RapportDc') {
    //     router.push('/community-distribution-report');
    //   } else {
    //     router.push('/liste-coupons-admin');
    //   }
  });
}

function deleteCoupon() {
  //const loader = $loading.show();
  store
    .dispatch('Commande/deleteCommande', dialogRef.value.data.order_id)
    .then(() => {
      //loader.hide();
      closeDialog();
      //router.push('/liste-coupons-admin');
    });
}
</script>
<template>
  <div class="contenu">
    <div class="choix-boite fond-blanc">
      <h2>Boites demandées</h2>
      <div class="ligne-pale"></div>
      <div class="boites">
        <div
          v-for="produitDC in produitsDC"
          v-bind:key="produitDC.id"
          class="choix-container-item"
        >
          <input
            type="checkbox"
            class="choix-container-item-checkbox"
            :value="produitDC.id"
            v-model="selectedCoupon"
            @change="updateSelectedProducts(produitDC)"
          />
          <span>{{ produitDC.nom }}</span>
          <img :src="produitDC.path_icone" alt="logo" />
        </div>
      </div>
      <div v-if="errorMessage">
        <p class="errorMessage">{{ errorMessage }}</p>
      </div>
    </div>
    <div class="form-site">
      <label for="site">
        Site de distribution où vous irez chercher votre commande
      </label>
      <select v-model="coupon.site_distribution_id">
        <option v-for="adress in adresses" :key="adress.id" :value="adress.id">
          {{ adress.ville.toUpperCase() }} - {{ adress.lieux }}
        </option>
      </select>
      <!-- <p
        class="errorMessage"
        v-for="error of v$.coupon.site_distribution_id.$errors"
        :key="error.$uid"
      >
        <span v-if="error.$validator == 'required'">
          {{ requiredMessage }}
        </span>
      </p> -->
    </div>
    <div class="bouton">
      <button class="button button-primary delete" @click="deleteCoupon">
        <font-awesome-icon
          :icon="['fas', 'trash-can']"
          class="box-check-logo"
        />
        Supprimer le coupon
      </button>
      <ConfirmDialog></ConfirmDialog>
      <button class="button button-primary" @click="submitform">
        <font-awesome-icon
          :icon="['fas', 'box-check']"
          class="box-check-logo"
        />
        Sauvegarder les modifications
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.contenu {
  display: flex;
  flex-direction: column;
}
.boites {
  display: flex;
  font-size: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (min-width: $xxl) {
    flex-direction: row;
  }
  .choix-container-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-basis: 100%;
    }

    img {
      width: 50px;
      height: 50px;
    }

    &-checkbox {
      width: 1em;
      height: 1em;
      accent-color: $bleuFonce;
    }
  }
}
select {
  max-width: 100%;
}
.form-site {
  flex-basis: 100%;
}
.form-site select {
  width: 100%;
}
.bouton {
  margin: 10px 0;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  @media screen and (min-width: $sm) {
    flex-direction: row;
  }
}
button.button {
  border: none;
  height: 3em;
}
.delete {
  background-color: red;
}
</style>


import { defineComponent } from 'vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { DetectedCode } from '../types/qrCodeReader/DetectedCode';
import { Context } from '../types/qrCodeReader/Context';
import QrcodeStream from 'vue-qrcode-reader/src/components/QrcodeStream.vue';

export default defineComponent({
  name: 'OrderScan',
  components: {
    QrcodeStream,
  },
  data() {
    return {
      camera: 'auto',
      commande: {
        uuid: '',
        site_distribution_id: -1,
      },
      isValid: undefined as undefined | boolean,
      showScanConfirmation: false,
      error: '',
      success: '',
      boitesDistribuees: '',
      boitesCommandees: '',
    };
  },
  computed: {
    validationPending(): boolean {
      return this.isValid === undefined && this.camera === 'off';
    },
    validationSuccess(): boolean {
      return this.isValid === true;
    },
    validationFailure(): boolean {
      return this.isValid === false;
    },
  },
  methods: {
    paintOutline(detectedCodes: Array<DetectedCode>, ctx: Context) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    onInit(promise: Promise<void>) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content: string) {
      // pretend it's taking really long
      this.turnCameraOff();
      await this.timeout(1000);

      this.commande.uuid = content;

      this.$loading.startLoading();
      this.$store
        .dispatch('Commande/reclamerCommande', this.commande)
        .then((data: { data; message: string }) => {
          this.nbBoitesDistribuees = data.data.nbBoitesDistribuees;
          this.nbBoitesCommandees = data.data.nbBoitesCommandees;
          this.isValid = true;
          this.success = data.message;
          this.$loading.stopLoading();
        })
        .catch((message: string) => {
          this.isValid = false;
          this.error = message;
          this.$loading.stopLoading();
        });

      // some more delay, so users have time to read the message
      await this.timeout(3000);

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = 'auto';
    },

    turnCameraOff() {
      this.camera = 'off';
    },

    timeout(ms: number) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  created() {
    this.$store.dispatch('Users/adminMe').then((data: UsersDTO) => {
      this.commande.site_distribution_id = data.site_distribution_id;
    });
  },
});


import { defineComponent } from 'vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';

export default defineComponent({
  name: 'ModificationUtilisateur',
  data() {
    return {
      adresses: {} as Array<siteDistributionDTO>,
      user: {},
      creation: false,
      message: '',
      error: false,
    };
  },
  created() {
    this.siteDistrib();
    if (this.$route.params.userId) {
      this.creation = false;
      this.getUserInfo(this.$route.params.userId as string);
    } else {
      this.creation = true;
    }
  },
  mounted() {
    if (!this.creation) {
      const form = this.$refs.formRole as HTMLElement;
      form.classList.add('full-width');
    }
  },
  methods: {
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    getUserInfo(userId: string) {
      this.$store
        .dispatch('Users/getUserById', userId)
        .then((data: UsersDTO) => {
          this.user = data;
        });
    },
    deleteUser() {
      if (confirm("Voulez-vous vraiment supprimer l'utilisateur?")) {
        this.$store
          .dispatch('Users/deleteUser', this.$route.params.userId as string)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    saveUser() {
      // this.user.password = this.password;
      if (this.creation) {
        this.$store
          .dispatch('Users/createUser', this.user)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      } else {
        this.$store
          .dispatch('Users/modifyUser', this.user)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
  },
});

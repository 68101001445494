
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
export default defineComponent({
  name: 'RapportSd',
  components: { DataTable, Column, Card },
  data() {
    return {
      adresses: [] as Array<siteDistributionDTO>,
      rappots: new Array<CommandeDTO>(),
      copieRappots: new Array<CommandeDTO>(),
      ico: 'far',
      site: -1,
      nomSite: '',
      search: '',
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getCommandes() {
      this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
        data.forEach((commande) => {
          commande.produits.forEach((produit) => {
            if (produit.nom === 'Boîte de poisson') {
              produit['type'] = 'poisson';
            } else if (produit.nom === 'Boîte de gibier') {
              produit['type'] = 'gibier';
            } else if (produit.nom === 'Boîte de saumon') {
              produit['type'] = 'saumon';
            }
            produit.ssamtaUser = commande.ssamtaUser;
            produit.site_distribution_id = commande.site_distribution_id;
            this.rappots.push(produit);
          });
        });
        this.copieRappots = this.rappots;
      });
    },
    filterDatatable() {
      this.rappots = this.copieRappots;
      if (this.site === '' || this.site == -1) {
        return;
      }
      const result = this.rappots.filter((rapport) => {
        return rapport.site_distribution_id === this.site;
      });
      this.rappots = result;
    },
    // async getRapports() {
    //   debugger;
    //   if (this.site != null) {
    //     this.$store
    //       .dispatch('Rapports/getRapportBySite', { site: this.site })
    //       .then((data: Array<CommandeDTO>) => {
    //         this.rappots = data;
    //         this.copieRappots = this.rappots;
    //       });
    //     const siteDistrib = this.adresses.find((add) => add.id === this.site);
    //     if (siteDistrib !== undefined) {
    //       this.nomSite = siteDistrib.ville;
    //     }
    //   }
    // },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande: CommandeDTO) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.firstName) ||
        this.getIfStringMatchSearch(commande.lastName) ||
        this.getIfStringMatchSearch(commande.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.adress) ||
        this.getIfStringMatchSearch(commande.city) ||
        this.getIfStringMatchSearch(commande.postalCode)
      );
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesSiteCsv', this.site).then();
    },
    exportPDF() {
      this.$store.dispatch('Rapports/getPdfSite', this.site).then();
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
  },
  created() {
    this.siteDistrib();
    this.getCommandes();
  },
});


import { defineComponent } from 'vue';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';

export default defineComponent({
  name: 'ListeCouponsAdmin',
  data() {
    return {
      commandes: new Array<CommandeDTO>(),
      commandesCopie: new Array<CommandeDTO>(),
      tableSize: -1,
      itemPerPages: 10,
      textSearch: '',
      currentPage: 1,
      totalPages: -1,
    };
  },
  created() {
    this.fetchCommandes();
  },
  methods: {
    getUrlCoupon(data) {
      return (
        process.env.VUE_APP_BACKEND_URL +
        'getCoupon/' +
        data.pivot.commande_id +
        '/' +
        data.pivot.uuid +
        '/' +
        data.type
      );
    },
    // getURLPoisson(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponPoisson/' + uuid;
    // },
    // getURLViande(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponGibier/' + uuid;
    // },
    // getURLSaumon(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponSaumon/' + uuid;
    // },
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    previousPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = this.currentPage - 1;
      this.updateCommandesDependingPagesAndSearch();
    },
    nextPage() {
      if (this.currentPage == this.totalPages) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      this.updateCommandesDependingPagesAndSearch();
    },
    fetchCommandes() {
      this.$store
        .dispatch('Rapports/getCommandesMadeByAdminsPerPage')
        .then((data: Array<CommandeDTO>) => {
          this.commandesCopie = data;
          this.updateCommandesDependingPagesAndSearch();
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.textSearch;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.lastName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.address) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.city) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.postalCode) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.telephone) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.mobile)
      );
    },
    updateCommandesDependingPagesAndSearch() {
      const commandes = this.commandesCopie.filter(this.getIfCommandeMatch);
      this.tableSize = commandes.length;
      this.totalPages = Math.ceil(this.tableSize / this.itemPerPages);
      this.commandes = commandes.splice(
        (this.currentPage - 1) * this.itemPerPages,
        this.itemPerPages
      );
    },
    downloadPDF(id: number, poisson: boolean, gibier: boolean) {
      if (poisson) {
        this.$store.dispatch('Rapports/getCouponPoisson', id).then();
      }
      if (gibier) {
        this.$store.dispatch('Rapports/getCouponGibier', id).then();
      }
    },
    toCreateCoupon() {
      this.$router.push({ name: 'CreationModificationCoupon' });
    },
    toModifyCoupon(id: string) {
      this.$router.push({ name: 'CreationModificationCoupon/' + id });
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesAdminCsv').then();
    },
  },
});

import { VuexModule, Module, Action } from 'vuex-module-decorators';
import UserService from '@/services/UserService';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { AxiosResponse } from 'axios';
import UsersService from '@/services/UsersService';
@Module({ namespaced: true })
class Commande extends VuexModule {
  @Action({ rawError: true })
  commander(data: CommandeDTO): Promise<AxiosResponse> {
    if (!isNaN(data.site_distribution_id)) {
      data.site_distribution_id = Number(data.site_distribution_id);
    }
    return UserService.commander(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  reclamerCommande(data: object): Promise<AxiosResponse> {
    return UserService.reclamerCommande(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  verifIfUserHaveCommandDeer() {
    return UserService.verifIfUserHaveCommandDeer().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  verifIfUserHaveCommandFish() {
    return UserService.verifIfUserHaveCommandFish().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  verifIfUserHaveCommandSaumon(memberId: string) {
    return UserService.verifIfUserHaveCommandSaumon().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandeById(id: string): Promise<string> {
    return UsersService.getCommandeById(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  createCommande(data): Promise<CommandeDTO> {
    return UsersService.createCommande(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  modifyCommande(data): Promise<CommandeDTO> {
    return UsersService.modifyCommande(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  deleteCommande(id: string): Promise<string> {
    return UsersService.deleteCommande(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  feedCoupon(data): Promise<CommandeDTO> {
    return UsersService.feedCoupon(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCoupons(memberId: string) {
    return UserService.getCoupons(memberId).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  updateSiteDistribution(data) {
    return UserService.updateSiteDistribution(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default Commande;

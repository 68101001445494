
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'RemercimentAssemblee',
  components: {
    MessageBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        confirmationInscription: 'Confirmation of registration',
        inscriptionRecue: {
          titre: 'Registration has been received',
          contenu: 'Thank you for your registration.',
        },
      },
      fr: {
        confirmationInscription: "Confirmation de l'inscription",
        inscriptionRecue: {
          titre: "L'inscription a bien été reçue",
          contenu: 'Merci pour votre inscription.',
        },
      },
    },
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  },
});


import { defineComponent } from 'vue';
import RapportDc from '@/components/rapports/RapportDc.vue';

export default defineComponent({
  name: 'RapportsDistributionCom',
  components: {
    RapportDc,
  },
});


import { defineComponent } from 'vue';
import { InscriptionAssembleeDTO } from '../types/store/DTO/InscriptionAssembleeDTO';
import commonMessages from '../locales/common';
import Panel from 'primevue/panel';
import RadioButton from 'primevue/radiobutton';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'FormulaireAssembleeGenerale',
  components: {
    RadioButton,
    Panel,
  },
  data() {
    return {
      nom: this.getStoredInfo('lastName'),
      prenom: this.getStoredInfo('firstName'),
      adresse: this.getStoredInfo('address'),
      ville: this.getStoredInfo('city'),
      codePostal: this.getStoredInfo('postalCode'),
      email: this.getStoredInfo('email'),
      numeroTelephone: this.getStoredInfo('telephone'),
      error: '',
      user: {} as InscriptionAssembleeDTO,
    };
  },
  validations: {
    nom: { required },
    prenom: { required },
    adresse: { required },
    ville: { required },
    codePostal: { required },
    email: { required },
    numeroTelephone: { required },
    user: {
      accompagnement: { required },
      nom_accompagnateur_1: {
        requiredIf: requiredIf(function () {
          return this.user.accompagnement === 'Oui';
        }),
      },
      num_membre_accompagnateur_1: {
        requiredIf: requiredIf(function () {
          return this.user.accompagnement === 'Oui';
        }),
      },
      num_membre_accompagnateur_2: {
        requiredIf: requiredIf(function () {
          return (
            this.user.accompagnement === 'Oui' &&
            this.user.nom_accompagnateur_2 != null &&
            this.user.nom_accompagnateur_2 != ''
          );
        }),
      },
      num_membre_accompagnateur_3: {
        requiredIf: requiredIf(function () {
          return (
            this.user.accompagnement === 'Oui' &&
            this.user.nom_accompagnateur_3 != null &&
            this.user.nom_accompagnateur_3 != ''
          );
        }),
      },
      allergies: { required },
      details_allergies: {
        requiredIf: requiredIf(function () {
          return this.user.allergies === 'Oui';
        }),
      },
      hebergement: { required },
      nb_chambres: {
        requiredIf: requiredIf(function () {
          return this.user.hebergement === 'Oui';
        }),
      },
      besoins_speciaux: {
        requiredIf: requiredIf(function () {
          return this.user.hebergement === 'Oui';
        }),
      },
      details_besoins_speciaux: {
        requiredIf: requiredIf(function () {
          return this.user.besoins_speciaux === 'Oui';
        }),
      },
    },
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        titrePage: 'Registration form for the general meeting',
        consigneValidite:
          'If this information is not valid, please update it from ',
        btnInscription: 'Register to the general meeting',
        votreProfil: 'your profile',
        erreurInscription: 'You are already registered for the general meeting',
        boolAccompagnementAssemblee:
          'Will you be accompanied to the General Meeting?',
        accompagnementDescription:
          'Enter the names of the people who will travel with you and who will participate in the meeting. Please note that only members will be able to participate in the GM and will be admitted to the room.',
        boolAllergies:
          'Do you or someone accompanying you have food allergies?',
        allergiesDescription:
          'If yes, who is this person and what allergy(ies) does he/she suffer from?',
        descriptionHebergement:
          'For members who live more than 75 km from the Hôtel Universel de Rivière-du Loup (source used: ' +
          'GoogleMap), the PNWW offers accommodation costs for two nights (Friday, June 7 and Saturday, June 8).' +
          'Accommodations for seniors (members over 75 years old) are available',
        boolHebergement: 'I live more than 75 km away and need accommodation',
        nbChambres:
          'How many rooms will you need (maximum of four people per room)',
        besoinsSpeciaux:
          'Do you have special needs for the room? Example: person with reduced mobility.',
        detailsBesoinsSpeciaux: 'If yes, give us more details',
        nomPrenom: 'Name and first name',
        numMembre: 'Member number',
        renseignements: 'Information',
        accompagnateurs: 'Accompanists',
        allergies: 'Allergies',
        hebergement: 'Accommodation',
        erreur_accompagnement: 'Please select if you will be accompanied.',
        erreur_nom_accompagnateur_1:
          'Please specify the name of the accompanist.',
        erreur_num_membre_accompagnateur_1:
          'Please specify the member number of the accompanist.',
        erreur_num_membre_accompagnateur_2:
          'Please specify the member number of the accompanist.',
        erreur_num_membre_accompagnateur_3:
          'Please specify the member number of the accompanist.',
        erreur_allergies: 'Please select if there are allergies.',
        erreur_details_allergies: 'Please specify the allergies.',
        erreur_hebergement: 'Please select if accommodation is necessary.',
        erreur_nb_chambres: 'Please specify the number of rooms needed.',
        erreur_besoins_speciaux: 'Please specify if you have special needs.',
        erreur_details_besoins_speciaux: 'Please specify the special needs.',
      },
      fr: {
        titrePage: "Formulaire d'inscription à l'assemblée générale",
        consigneValidite:
          'Si ces informations ne sont pas valides, veuillez les modifier à partir de ',
        btnInscription: "Je m'inscris à l'assemblée générale",
        votreProfil: 'votre profil',
        erreurInscription: "Vous êtes déjà inscrit à l'assemblée générale",
        boolAccompagnementAssemblee:
          "Serez-vous accompagné à l'Assemblée générale ?",
        accompagnementDescription:
          "Inscrire le nom des personnes qui voyageront avec vous et qui participeront à la rencontre. À noter que seuls les membres pourront participer à la l'AGA et seront admis dans la salle.",
        boolAllergies:
          'Est-ce que vous ou une personne qui vous accompagne a des allergies alimentaires?',
        allergiesDescription:
          'Si oui, qui est cette personne et de quelle(s) allergie(s) souffre-t-elle ?',
        descriptionHebergement:
          "Pour les membres qui habitent à plus de 75 km de l'Hôtel Universel de Rivière-du Loup (source utilisée : " +
          "GoogleMap), la PNWW offre les frais d'hébergement pour deux nuits (vendredi 7 juin et samedi 8 juin)." +
          'Des accommodements pour les aînés (membres de plus de 75 ans) sont disponibles',
        boolHebergement:
          "J'habite à plus de 75 km et j'ai besoin d'hébergement",
        nbChambres:
          'Combien de chambres aurez-vous besoin (maximum de quatre personnes par chambre)',
        besoinsSpeciaux:
          'Avez-vous des besoins spéciaux pour la chambre? Exemple : personne à mobilité réduite.',
        detailsBesoinsSpeciaux: 'Si oui, donnez-nous plus de détails',
        nomPrenom: 'Nom et prénom',
        numMembre: 'Numéro de membre',
        renseignements: 'Renseignements',
        accompagnateurs: 'Accompagnateurs',
        allergies: 'Allergies',
        hebergement: 'Hébergement',
        erreur_accompagnement:
          'Veuillez sélectionnez si vous serez accompagné.',
        erreur_nom_accompagnateur_1:
          "Veuillez spécifier le nom de l'accompagnateur.",
        erreur_num_membre_accompagnateur_1:
          "Veuillez spécifier le numéro de membre de l'accompagnateur.",
        erreur_num_membre_accompagnateur_2:
          "Veuillez spécifier le numéro de membre de l'accompagnateur.",
        erreur_num_membre_accompagnateur_3:
          "Veuillez spécifier le numéro de membre de l'accompagnateur.",
        erreur_allergies: "Veuillez sélectionnez s'il y a des allergies.",
        erreur_details_allergies: 'Veuillez spécifier les allergies.',
        erreur_hebergement:
          "Veuillez sélectionnez si l'hébergement est nécessaire.",
        erreur_nb_chambres:
          'Veuillez spécifier le nombre de chambres nécessaire.',
        erreur_besoins_speciaux:
          'Veuillez spécifier si vous avez des besoins spéciaux.',
        erreur_details_besoins_speciaux:
          'Veuillez spécifier les besoins spéciaux.',
      },
    },
  },
  methods: {
    submitForm() {
      this.user.memberId = this.getStoredInfo('memberId') as string;
      this.user.age = JSON.parse(sessionStorage.getItem('age') as string);
      // Validate if all radio buttons have a value
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.$loading.startLoading();
      this.$store
        .dispatch('InscriptionAssemblee/inscriptionAssemblee', this.user)
        .then(() => {
          this.$loading.stopLoading();
          this.error = '';
          this.$router.push('/remercimentAssemblee');
        })
        .catch((responseError) => {
          this.error = responseError;
          this.$loading.stopLoading();
        });
    },
    getStoredInfo(info: string) {
      const test = JSON.parse(sessionStorage.getItem('user') || '');
      for (const [key, value] of Object.entries(test)) {
        if (key === info) return value;
      }
    },
  },
});

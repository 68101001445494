
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';
import JsonCSV from 'vue-json-csv';
export default defineComponent({
  name: 'ListeInscriptionsAssemblee',
  components: { DataTable, Column, Card, JsonCSV },
  data() {
    return {
      search: '',
      inscriptionsList: new Array<InscriptionAssembleeDTO>(),
      inscriptionsListCopie: new Array<InscriptionAssembleeDTO>(),
      ico: 'far',
      selectedUser: {} as InscriptionAssembleeDTO,
      minDate: null,
      maxDate: null,
      isDisabled: true,
    };
  },
  methods: {
    getInscriptionsWithDates() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$loading.startLoading();
        this.$store
          .dispatch('Rapports/inscriptionsWithDates', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then((data: Array<InscriptionAssembleeDTO>) => {
            if (data != undefined) {
              this.inscriptionsList = data;
              this.inscriptionsListCopie = data;
            } else {
              this.inscriptionsList = new Array<InscriptionAssembleeDTO>();
              this.inscriptionsListCopie = new Array<InscriptionAssembleeDTO>();
            }
            this.$loading.stopLoading();
          })
          .catch(() => {
            this.$loading.stopLoading();
          })
          .finally(() => {
            this.$loading.stopLoading();
          });
      }
    },
    checkDisabled() {
      if (
        this.minDate == null ||
        this.maxDate == null ||
        this.minDate == '' ||
        this.maxDate == ''
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    dateParserLong(date: string) {
      return dateParserLong(date);
    },
    getInscriptions() {
      this.$loading.startLoading();
      this.$store
        .dispatch('InscriptionAssemblee/getAllInscriptions')
        .then((data: Array<InscriptionAssembleeDTO>) => {
          if (data != undefined) {
            this.inscriptionsList = data;
            this.inscriptionsListCopie = data;
          } else {
            this.inscriptionsList = new Array<InscriptionAssembleeDTO>();
            this.inscriptionsListCopie = new Array<InscriptionAssembleeDTO>();
          }
          this.$loading.stopLoading();
        })
        .catch(() => {
          this.$loading.stopLoading();
        })
        .finally(() => {
          this.$loading.stopLoading();
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(this.getNormalizedString(search))
      );
    },
    getIfUserMatch(user: InscriptionAssembleeDTO) {
      return (
        this.getIfStringMatchSearch(user.nom) ||
        this.getIfStringMatchSearch(user.prenom) ||
        this.getIfStringMatchSearch(user.email) ||
        this.getIfStringMatchSearch(user.dateCreation) ||
        this.getIfStringMatchSearch(user.codePostal) ||
        this.getIfStringMatchSearch(user.telephone) ||
        this.getIfStringMatchSearch(user.ville) ||
        this.getIfStringMatchSearch(user.adresse) ||
        this.getIfStringMatchSearch(user.generation) ||
        this.getIfStringMatchSearch(user.age.toString()) ||
        this.getIfStringMatchSearch(user.nom_accompagnateur_1) ||
        this.getIfStringMatchSearch(user.nom_accompagnateur_2) ||
        this.getIfStringMatchSearch(user.nom_accompagnateur_3) ||
        this.getIfStringMatchSearch(user.num_membre_accompagnateur_1) ||
        this.getIfStringMatchSearch(user.num_membre_accompagnateur_2) ||
        this.getIfStringMatchSearch(user.num_membre_accompagnateur_3) ||
        this.getIfStringMatchSearch(user.details_allergies) ||
        this.getIfStringMatchSearch(user.details_besoins_speciaux)
      );
    },
    filter() {
      this.inscriptionsList = this.inscriptionsListCopie;
      const result = this.inscriptionsList.filter(this.getIfUserMatch);
      this.inscriptionsList = result;
    },
  },
  created() {
    this.getInscriptions();
  },
});

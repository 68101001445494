
import { defineComponent } from 'vue';
import Requestbox from '@/components/orderValidation/Requestbox.vue';
import InformationFrom from '@/components/orderValidation/InformationFrom.vue';
import TotalBox from '@/components/orderValidation/TotalBox.vue';
import Card from '@/components/Card.vue';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { Panier } from '@/types/Panier';
import * as Square from '@square/web-sdk';
import EventBus from '@/events/EventBus';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'ConfirmationCommande',
  components: {
    Requestbox,
    Card,
    InformationFrom,
    TotalBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        consigneInfosProfil:
          'If your information is not up to date, please update it now here.',
        confirmationCommande: 'Order confirmation',
        boitesDemandees: 'Requested boxes',
        methodePaiement: 'Method of payment',
        infosEnvoi: 'Shipping Information',
      },
      fr: {
        consignesInfosProfil:
          'Si vos informations ne sont pas à jour, veuillez les mettre à jour dès maintenant ici.',
        confirmationCommande: 'Confirmation de la commande',
        boitesDemandees: 'Boîtes demandées',
        methodePaiement: 'Méthode de paiement',
        infosEnvoi: 'Shipping Information',
      },
    },
  },
  data() {
    return {
      distribution: -1,
      isApplicationFormInvalid: true,
      isPaiementFormInvalid: true,
      noCard: '',
      expirationDate: '',
      secureCode: '',
      commandInfo: {} as CommandeDTO,
      panier: {} as Panier,
      errorCommand: false,
      message: '',
      totalPrice: 0,
      loading: false,
    };
  },
  methods: {
    goToRemerciment() {
      this.$router.push('/remerciment');
    },
    goToDistributionCom() {
      this.$router.push('/community-distribution');
    },
    getInfoFormInterface(infoFormInterface: undefined) {
      this.$options.infoFormInterface = infoFormInterface;
    },
    getPaiementFormInterface(paiementFormInterface: undefined) {
      this.$options.paiementFormInterface = paiementFormInterface;
    },
    commander(cnon?: string) {
      const isSiteDistribSelectionnee = this.distribution >= 0;
      if (!this.validate() && isSiteDistribSelectionnee) {
        this.commandInfoData(cnon);
        this.$loading.startLoading();
        this.$store
          .dispatch('Commande/commander', this.commandInfo)
          .then(() => {
            this.$loading.stopLoading();
            this.panier.gibier = false;
            this.panier.poisson = false;
            this.panier.saumon = false;
            sessionStorage.panier = JSON.stringify(this.panier);
            this.goToRemerciment();
            this.loading = false;
          })
          .catch((message: string) => {
            this.errorCommand = true;
            this.message = message;
            this.$loading.stopLoading();
            this.loading = false;
          });
      } else {
        if (!isSiteDistribSelectionnee) {
          this.errorCommand = true;
          this.message = 'Veuillez sélectionner un site de distribution';
        }
        this.loading = false;
      }
    },
    commandInfoData(cnon?: string) {
      const user = JSON.parse(sessionStorage.user);
      const panier = JSON.parse(sessionStorage.panier);
      // this.commandInfo.firstName = user.firstName;
      // this.commandInfo.lastName = user.lastName;
      // this.commandInfo.adress = user.address;
      // this.commandInfo.city = user.city;
      // this.commandInfo.postalCode = user.postalCode;
      // this.commandInfo.telephone = user.telephone;
      // this.commandInfo.email = user.email;
      this.commandInfo.site_distribution_id = this.distribution;
      // this.commandInfo.province = user.province;
      this.commandInfo.memberId = user.memberId;
      this.commandInfo.poisson = panier.poisson;
      this.commandInfo.gibier = panier.gibier;
      this.commandInfo.saumon = panier.saumon;
      this.commandInfo.transactionID = cnon;
    },
    validate() {
      this.isApplicationFormInvalid =
        this.$options.infoFormInterface.checkValide();
      return this.isApplicationFormInvalid;
    },
    async initCard() {
      const applicationId = process.env.VUE_APP_SQUARE_APPLICATION_ID;
      const locationId = process.env.VUE_APP_SQUARE_LOCATION_ID;
      const payments = await Square.payments(applicationId, locationId);
      const card = await payments!.card();
      await card.attach('#card-container');
      const button = this.$refs.cardButton as HTMLElement;
      button.addEventListener('click', async () => {
        this.loading = true;
        try {
          if (this.totalPrice > 0) {
            const result = await card.tokenize();
            this.commander(result.token!);
          } else {
            this.commander();
          }
        } catch (ex) {
          console.error(ex);
          this.loading = false;
        }
      });
    },
    calculerPrix() {
      this.panier = JSON.parse(sessionStorage.panier);
      this.totalPrice = 0;
      if (this.panier.poisson) {
        this.totalPrice += 20;
      }
    },
    async panierChange() {
      this.calculerPrix();
    },
  },
  mounted() {
    this.initCard();
    EventBus.on('panierChange', this.panierChange);
  },
  created() {
    const panier = JSON.parse(sessionStorage.panier);
    if (!panier.poisson && !panier.gibier && !panier.saumon) {
      this.goToDistributionCom();
    }
    const user = JSON.parse(sessionStorage.user);
    this.calculerPrix();
  },
});
